import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

//Layout
import Navtop from "./layout/Navtop";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import DocElectSinEmb from "./pages/DocElectSinEmb";
import Matelect from "./pages/Matelect";
import DocElectConEmb from "./pages/DocElectConEmb";

function App() {
  return (
    <Router>
      <Navtop />
      <Navbar />
      <div className="container">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/docsinemb" element={<DocElectSinEmb />} />
          <Route path="/docconemb" element={<DocElectConEmb />} />
          <Route path="/matelect" element={<Matelect />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;

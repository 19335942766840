import React, { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { data } from "../data/dataMatElect";
import TitlePages from "../layout/TitlePages";

const Matelect = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "NO.",
        footer: "NO.",
      },
      {
        accessorKey: "nombre",
        header: "NO.",
        footer: "NO.",
      },
      {
        id: "linkDoc",
        header: "",
        footer: "",
        Cell: ({ row }) =>
          row.original.linkDoc === "" ? (
            <span></span>
          ) : (
            <a href={row.original.linkDoc} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
            </a>
          ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    initialState: { density: "compact" },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#972069",
        color: "#fff",
      },
    },
    muiTableFooterCellProps: {
      sx: {
        backgroundColor: "#972069",
        color: "#fff",
      },
    },
  });

  return (
    <>
      <TitlePages title="Repositorio" subTitle="Material Electoral" />
      <MaterialReactTable table={table} />
    </>
  );
};
export default Matelect;

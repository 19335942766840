import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Navtop extends Component {
  render() {
    return (
      <nav className="navbar bg-ite">
        <div className="container-fluid">
          <div className="bg-blanco ps-2  pt-2 pb-2">
            <Link className="navbar-brand" to="./">
              <img
                src="https://itetlax.org.mx/assets/img/logoite.png"
                alt="Logo ITE"
                height="40"
                className="d-inline-block align-text-top"
              />
            </Link>
          </div>
          <div className="text-center fs-3 text-blanco">
            <b>Instituto Tlaxcalteca de Elecciones</b>
          </div>
        </div>
      </nav>
    );
  }
}

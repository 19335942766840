export const data = [
  {
    id: 1,
    nombre: "Caja Contenedora de Material 20 cm Tlaxcala",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/1.pdf",
  },
  {
    id: 2,
    nombre: "Caja Paquete Ayuntamiento 15 cm SCE 1.1",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/2.pdf",
  },
  {
    id: 3,
    nombre: "Caja Paquete Diputaciones 15 cm SCE 1.1",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/3.pdf",
  },
  {
    id: 4,
    nombre: "Caja Paquete Otra Elección 15 cm SCE 1.1",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/4.pdf",
  },
  {
    id: 5,
    nombre: "Cancel Electoral OPL Tlaxcala",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/5.pdf",
  },
  {
    id: 6,
    nombre: "Cinta de Seguridad Tlaxcala",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/6.pdf",
  },
  {
    id: 7,
    nombre: "Etiqueta Braille Ayuntamiento Tlaxcala",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/7.pdf",
  },
  {
    id: 8,
    nombre: "Etiqueta Braille Diputaciones Locales Tlaxcala",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/8.pdf",
  },
  {
    id: 9,
    nombre: "Etiqueta Braille Otra Elección Tlaxcala",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/9.pdf",
  },
  {
    id: 10,
    nombre: "Marcador de Boletas Tlaxcala",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/10.pdf",
  },
  {
    id: 11,
    nombre: "Urna Ayuntamiento Tlaxcala",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/11.pdf",
  },
  {
    id: 12,
    nombre: "Urna Diputaciones Locales Tlaxcala",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/12.pdf",
  },
  {
    id: 13,
    nombre: "Urna Otra Elección Tlaxcala",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/matelect/13.pdf",
  },
];

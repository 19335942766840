import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

export default class Navbar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg bg-body-tertiary shadow mb-5">
        <div className="container-fluid">
          <Link className="navbar-brand" to="./">
            <FontAwesomeIcon icon={faHome} className="btn btn-danger" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <Link className="nav-link" to="./docsinemb">
                Documentación Electoral sin emblemas
              </Link>
              <Link className="nav-link" to="./docconemb">
                Documentación Electoral con emblemas
              </Link>
              <Link className="nav-link" to="./matelect">
                Materiales Electorales
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

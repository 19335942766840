export const data = [
  {
    id: 1,
    nombre: "ACTA ELECTORES TRANSITO",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/1.pdf",
  },
  {
    id: 2,
    nombre: "BOLSA ACTA DE ELECTORES",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/2.pdf",
  },
  {
    id: 3,
    nombre: "BOLSA ACTAS DE ESCRUTINIO POR FUERA PAQUETE 01 DIPUTACIONES",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/3.pdf",
  },
  {
    id: 4,
    nombre: "BOLSA ACTAS DE ESCRUTINIO POR FUERA PAQUETE MUNICIPAL",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/4.pdf",
  },
  {
    id: 5,
    nombre: "BOLSA BOLETAS SOBRANTES AYUNTAMIENTO",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/5.pdf",
  },
  {
    id: 6,
    nombre: "BOLSA BOLETAS SOBRANTES DIPUTACIONES L",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/6.pdf",
  },
  {
    id: 7,
    nombre: "BOLSA BOLETAS SOBRANTES OTRA ELECCIÓN ",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/7.pdf",
  },
  {
    id: 8,
    nombre: "BOLSA EXPEDIENTE CASILLA AYUNTAMIENTO",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/8.pdf",
  },
  {
    id: 9,
    nombre: "BOLSA EXPEDIENTE CASILLA DIPUTACIONES L",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/9.pdf",
  },
  {
    id: 10,
    nombre: "BOLSA EXPEDIENTE CASILLA ESPECIAL AYUNTAMIENTO",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/10.pdf",
  },
  {
    id: 11,
    nombre: "BOLSA EXPEDIENTE CASILLA ESPECIAL DIPUTACIONES L",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/11.pdf",
  },
  {
    id: 12,
    nombre: "BOLSA PRESIDENTE DE CASILLA AYUNTAMIENTO",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/12.pdf",
  },
  {
    id: 13,
    nombre: "BOLSA PRESIDENTE DE CASILLA DIPUTACIONES L",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/13.pdf",
  },
  {
    id: 14,
    nombre: "BOLSA PRESIDENTE DE CASILLA OTRA ELECCIÓN",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/14.pdf",
  },
  {
    id: 15,
    nombre: "BOLSA VOTOS NULOS AYUNTAMIENTO",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/15.pdf",
  },
  {
    id: 16,
    nombre: "BOLSA VOTOS NULOS DIPUTACIONES L",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/16.pdf",
  },
  {
    id: 17,
    nombre: "BOLSA VOTOS NULOS OTRA ELECCIÓN",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/17.pdf",
  },
  {
    id: 18,
    nombre: "BOLSA VOTOS VÁLIDOS AYUNTAMIENTO",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/18.pdf",
  },
  {
    id: 19,
    nombre: "BOLSA VOTOS VÁLIDOS DIPUTACIONES L",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/19.pdf",
  },
  {
    id: 20,
    nombre: "BOLSA VOTOS VÁLIDOS OTRA ELECCIÓN",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/20.pdf",
  },
  {
    id: 21,
    nombre: "CARTEL IDENTIFICACION DE CASILLA ESPECIAL",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/21.pdf",
  },
  {
    id: 22,
    nombre: "CARTEL INFORMATIVO AYUNTAMIENTO",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/22.pdf",
  },
  {
    id: 23,
    nombre: "CARTEL INFORMATIVO DIPUTACIONES L",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/23.pdf",
  },
  {
    id: 24,
    nombre: "CARTEL INFORMATIVO OTRA ELECCIÓN",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/24.pdf",
  },
  {
    id: 25,
    nombre: "CONSTANCIA DE MAYORÍA Y VALIDEZ DIPUTACIONES L",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/25.pdf",
  },
  {
    id: 26,
    nombre: "CONSTANCIA MAYORIA Y VALIDEZ AYUNTAMIENTO",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/26.pdf",
  },
  {
    id: 27,
    nombre: "CONSTANCIA MAYORÍA Y VALIDEZ OTRA ELECCIÓN",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/27.pdf",
  },
  {
    id: 28,
    nombre: "RECIBO DE ENTREGA DE LOS PAQUETES  CRYT",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/28.pdf",
  },
  {
    id: 29,
    nombre: "RECIBO DOCUMENTACIÓN MATERIALES CE PMDC",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/29.pdf",
  },
  {
    id: 30,
    nombre: "RECIBO DOCUMENTACIÓN MATERIALES PMDC",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/30.pdf",
  },
  {
    id: 31,
    nombre: "RECIBO ENTREGA DEL PAQUETE ELECTORAL",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/31.pdf",
  },
  {
    id: 32,
    nombre: "REVERSO BOLSAS",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/32.pdf",
  },
  {
    id: 33,
    nombre: "SOBRE EXPEDIENTE CASILLA CE OTRA ELECCIÓN",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/33.pdf",
  },
  {
    id: 34,
    nombre: "SOBRE EXPEDIENTE CASILLA OTRA ELECCIÓN",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/34.pdf",
  },
  {
    id: 35,
    nombre: "SOBRE VOTOS OTRAS URNAS AYUNTAMIENTO",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/35.pdf",
  },
  {
    id: 36,
    nombre: "SOBRE VOTOS OTRAS URNAS DIPUTACIONES L",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/36.pdf",
  },
  {
    id: 37,
    nombre: "SOBRE VOTOS OTRAS URNAS OTRA ELECCIÓN",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docsinemb/37.pdf",
  },
];

export const data = [
  {
    id: 1,
    categoria: "comun",
    nombre: "ACTA jornada electoral",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/comun/1.pdf",
  },
  {
    id: 2,
    categoria: "comun",
    nombre: "HOJA incidentes",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/comun/2.pdf",
  },
  {
    id: 3,
    categoria: "comun",
    nombre: "CONSTANCIA clausura casilla",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/comun/3.pdf",
  },
  {
    id: 4,
    categoria: "comun",
    nombre: "CARTEL resultados casilla",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/comun/4.pdf",
  },
  {
    id: 5,
    categoria: "comun",
    nombre: "CARTEL resultados casilla CE",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/comun/5.pdf",
  },
  {
    id: 6,
    categoria: "comun",
    nombre: "CARTEL resultados preliminares distrito",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/comun/6.pdf",
  },
  {
    id: 7,
    categoria: "comun",
    nombre: "CARTEL resultados preliminares municipio",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/comun/7.pdf",
  },
  {
    id: 8,
    categoria: "comun",
    nombre: "CARTEL cómputo distrital",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/comun/8.pdf",
  },
  {
    id: 9,
    categoria: "comun",
    nombre: "CARTEL cómputo municipal",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/comun/9.pdf",
  },
  {
    id: 10,
    categoria: "diputaciones locales",
    nombre: "BOLETA diputación local tamaño carta",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/1.pdf",
  },
  {
    id: 11,
    categoria: "diputaciones locales",
    nombre: "ACTA escrutinio y cómputo diputación local",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/2.pdf",
  },
  {
    id: 12,
    categoria: "diputaciones locales",
    nombre: "acta escrutinio y computo ce mr diputación local",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/3.pdf",
  },
  {
    id: 13,
    categoria: "diputaciones locales",
    nombre: "acta escrutinio y computo ce rp diputación local",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/4.pdf",
  },
  {
    id: 14,
    categoria: "diputaciones locales",
    nombre: "acta escrutinio y computo levantada cd diputación local mr",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/5.pdf",
  },
  {
    id: 15,
    categoria: "diputaciones locales",
    nombre: "cta escrutinio y computo levantada cd diputación local rp",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/6.pdf",
  },
  {
    id: 16,
    categoria: "diputaciones locales",
    nombre: "acta cómputo distrital diputación local mr",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/7.pdf",
  },
  {
    id: 17,
    categoria: "diputaciones locales",
    nombre: "acta cómputo distrital diputación local rp",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/8.pdf",
  },
  {
    id: 18,
    categoria: "diputaciones locales",
    nombre: "plantilla braile diputación local tamaño carta",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/9.pdf",
  },
  {
    id: 19,
    categoria: "diputaciones locales",
    nombre: "guía apoyo diputación local tamaño carta",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/10.pdf",
  },
  {
    id: 20,
    categoria: "diputaciones locales",
    nombre: "clasificador votos diputación local tamaño carta",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/11.pdf",
  },
  {
    id: 21,
    categoria: "diputaciones locales",
    nombre: "constancia individual diputación local",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/12.pdf",
  },
  {
    id: 22,
    categoria: "diputaciones locales",
    nombre: "hoja de operaciones diputaciones locales cbce",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/13.pdf",
  },
  {
    id: 23,
    categoria: "diputaciones locales",
    nombre: "hoja de operaciones locales ce",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/diploc/14.pdf",
  },
  {
    id: 24,
    categoria: "ayuntamientos",
    nombre: "Acta de cómputo municipal de la elección para el Ayuntamiento",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/1.pdf",
  },
  {
    id: 25,
    categoria: "ayuntamientos",
    nombre:
      "Acta de escrutinio y cómputo de casilla levantada en el consejo municipal de la elección de Ayuntamiento",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/2.pdf",
  },
  {
    id: 26,
    categoria: "ayuntamientos",
    nombre: "ACTA escrutinio cómputo CE MR AYUNTAMIENTO",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/3.pdf",
  },
  {
    id: 27,
    categoria: "ayuntamientos",
    nombre: "ACTA escrutinio y cómputo AYUNTAMIENTO",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/4.pdf",
  },
  {
    id: 28,
    categoria: "ayuntamientos",
    nombre: "HOJA DE OPERACIONES AYUNTAMIENTO CE",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/5.pdf",
  },
  {
    id: 29,
    categoria: "ayuntamientos",
    nombre: "HOJA DE OPERACIONES AYUNTAMIENTO CBCE",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/6.pdf",
  },
  {
    id: 30,
    categoria: "ayuntamientos",
    nombre: "Constancia individual AYUNTAMIENTO",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/7.pdf",
  },
  {
    id: 31,
    categoria: "ayuntamientos",
    nombre: "CLASIFICADOR votos AYUNTAMIENTO TAMAÑO CARTA",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/8.pdf",
  },
  {
    id: 32,
    categoria: "ayuntamientos",
    nombre: "GUÍA de apoyo AYUNTAMIENTO TAMAÑO CARTA",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/9.pdf",
  },
  {
    id: 33,
    categoria: "ayuntamientos",
    nombre: "Plantilla Braille AYUNTAMIENTO carta",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/10.pdf",
  },
  {
    id: 34,
    categoria: "ayuntamientos",
    nombre: "BOLETA AYUNTAMIENTO TAMAÑO CARTA",
    linkDoc:
      "https://repositorio.itetlax.org.mx/assets/docconemb/ayuntamientos/11.pdf",
  },
  {
    id: 35,
    categoria: "pc",
    nombre: "BOLETA OTRA ELECCIÓN TAMAÑO CARTA",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/1.pdf",
  },
  {
    id: 36,
    categoria: "pc",
    nombre: "ACTA escrutinio y cómputo PC",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/2.pdf",
  },
  {
    id: 37,
    categoria: "pc",
    nombre: "ACTA escrutinio cómputo PC_ CE MR",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/3.pdf",
  },
  {
    id: 38,
    categoria: "pc",
    nombre:
      "Acta de escrutinio y cómputo de casilla levantada en el consejo municipal de la elección de PC",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/4.pdf",
  },
  {
    id: 39,
    categoria: "pc",
    nombre: "Acta de cómputo municipal de la elección PC",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/5.pdf",
  },
  {
    id: 40,
    categoria: "pc",
    nombre: "07 Plantilla Braille PC",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/6.pdf",
  },
  {
    id: 41,
    categoria: "pc",
    nombre: "GUÍA de apoyo PC",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/7.pdf",
  },
  {
    id: 42,
    categoria: "pc",
    nombre: "CLASIFICADOR votos PC CARTA",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/8.pdf",
  },
  {
    id: 43,
    categoria: "pc",
    nombre: "Constancia individual PC",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/9.pdf",
  },
  {
    id: 44,
    categoria: "pc",
    nombre: "HOJA DE OPERACIONES PC CBCE",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/10.pdf",
  },
  {
    id: 45,
    categoria: "pc",
    nombre: "HOJA DE OPERACIONES PC CE",
    linkDoc: "https://repositorio.itetlax.org.mx/assets/docconemb/pc/11.pdf",
  },
];

import React, { Component } from "react";
import { Link } from "react-router-dom";
import folder from "../img/folder.png";
import TitlePages from "../layout/TitlePages";

export default class Home extends Component {
  render() {
    return (
      <>
        <TitlePages title="Repositorio" subTitle="" />
        <div className="row text-center">
          <div className="col-md-4">
            <Link to={"/docsinemb"}>
              <img
                src={folder}
                className="img-fluid img__hover w-60"
                alt="Documentación Electoral sin emblemas"
              />
            </Link>
          </div>
          <div className="col-md-4">
            <Link to={"/docconemb"}>
              <img
                src={folder}
                className="img-fluid img__hover w-60"
                alt="Documentación Electoral con emblemas"
              />
            </Link>
          </div>
          <div className="col-md-4">
            <Link to={"/matelect"}>
              <img
                src={folder}
                className="img-fluid img__hover w-60"
                alt="Materiales Electorales"
              />
            </Link>
          </div>
        </div>
        <div className="row text-center mt-3">
          <div className="col-md-4 text-ite fw-bolder">
            <span className="fs-3">Documentación Electoral sin emblemas</span>
          </div>
          <div className="col-md-4 text-ite fw-bolder">
            <span className="fs-3">Documentación Electoral con emblemas</span>
          </div>
          <div className="col-md-4 text-ite fw-bolder">
            <span className="fs-3">Materiales Electorales</span>
          </div>
        </div>
        <br />
        <br />
      </>
    );
  }
}
